<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t('Copla.Menu.Applications') }}</h1>
        <div class="container-fluid">
          <div class="row mt-4">
            <div class="card bg-light mr-2 border-success" style="width: 49%">
              <div class="card-body table-responsive">
                <h2 class="card-title text-center">STARTIT - Translation</h2>
                <hr class="border-success" />
                <p class="card-text">
                  Aplicación creada para poder exportar desde un archivo de
                  STARTIT a un Excel para enviar a traducir, y para poder
                  generar el STARTIT completo una vez generada la traducción.
                </p>
                <p>
                  Para su utilización, descargar el archivo y descomprimir en
                  cualquier carpeta. Ejecutar el archivo StartitTranslation.exe
                  (se puede crear un acceso directo para una rápida ejecución)
                </p>
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Versión</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Descarga</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.0.1</td>
                      <td>25/06/2024</td>
                      <td>
                        <a
                          href="/files/Apps/STARTITTranslation/StartitTranslation1.0.1.rar"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.0.0</td>
                      <td>16/04/2024</td>
                      <td>
                        <a
                          href="/files/Apps/STARTITTranslation/StartitTranslation.rar"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card bg-light border-success" style="width: 49%">
              <div class="card-body table-responsive">
                <h2 class="card-title text-center">Duplicate STI</h2>
                <hr class="border-success" />
                <p class="card-text">
                  Aplicación creada para poder duplicar un idioma en un archivo STI y tener dos salidas iguales.
                </p>
                <p>
                  Para su utilización, descargar el archivo y descomprimir en
                  cualquier carpeta. Ejecutar el archivo DuplicateSTI.exe
                  (se puede crear un acceso directo para una rápida ejecución)
                </p>
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Versión</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Descarga</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.0.0</td>
                      <td>16/04/2024</td>
                      <td>
                        <a
                          href="/files/Apps/DuplicateSTI/DuplicateSTI.rar"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card bg-light mr-2 mt-2 border-success" style="width: 49%">
              <div class="card-body table-responsive">
                <h2 class="card-title text-center">ADJoin</h2>
                <hr class="border-success" />
                <p class="card-text">
                  Aplicación creada para poder unir todos los archivos de audiodescripción en un único archivo de salida siguiendo un guion .srt.
                </p>
                <p>
                  Para su utilización, descargar el archivo y descomprimir en
                  cualquier carpeta. Ejecutar el archivo ADjoin.exe
                  (se puede crear un acceso directo para una rápida ejecución)
                </p>
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Versión</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Descarga</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.0.3</td>
                      <td>16/04/2024</td>
                      <td>
                        <a
                          href="/files/Apps/ADJoin/ADJoin_1.0.3.rar"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.0.2</td>
                      <td>15/04/2024</td>
                      <td>
                        <a
                          href="/files/Apps/ADJoin/ADJoin_1.0.2.rar"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card bg-light mt-2 border-success" style="width: 49%">
              <div class="card-body table-responsive">
                <h2 class="card-title text-center">STARTIT</h2>
                <hr class="border-success" />
                <p class="card-text">
                  Solución tecnológica creada para ofrecer accesibilidad a la
                  comunicación en cualquier evento y espacio.
                </p>
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Versión</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Descarga</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.3.4</td>
                      <td>11/11/2024</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.3.4.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.3.3</td>
                      <td>10/06/2024</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.3.3.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.3.2</td>
                      <td>22/04/2024</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.3.2.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.3.1</td>
                      <td>04/04/2024</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.3.1.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.3.0</td>
                      <td>21/03/2024</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.3.0.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.2.6</td>
                      <td>12/01/2024</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.2.6.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.2.4</td>
                      <td>14/09/2023</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.2.4.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.2.3</td>
                      <td>19/04/2023</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.2.3.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.2.2</td>
                      <td>20/12/2021</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.2.2.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>1.2.1</td>
                      <td>15/04/2021</td>
                      <td>
                        <a
                          href="/files/Apps/STARTIT/Setup_STARTIT_1.2.1.msi"
                          class="btn btn-primary"
                          >Descarga</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import toolsUsersAPI from '../js/toolsUsersAPI'
import MainMenu from '../components/MainMenu'
import ModalLoading from '../components/ModalLoading.vue'
export default {
  name: 'Applications',
  components: {
    MainMenu,
    ModalLoading
  },
  data () {
    return {
      id: -1,
      email: this.$store.state.user,
      oldEmail: '',
      password1: '',
      password2: '',
      usersAPI: new toolsUsersAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      showModalLoading: false,
      messageModal: '',
      message: ''
    }
  },
  computed: {
    dataSend: function () {
      return { _login: this.email, _password: this.password1 }
    }
  },
  methods: {
    ...mapActions(['logout']),
    cleanPasswords () {
      this.password1 = ''
      this.password2 = ''
    },
    showModal (status, message = '') {
      this.messageModal = message
      this.showModalLoading = status
    },
    updateUser () {
      if (this.email != this.oldEmail) {
        if (confirm(this.$t('Copla.Common.Messages.ConfirmLogout'))) {
          this.message = ''
          this.showModal(true, this.$t('Copla.Common.Modal.Updating'))
          this.usersAPI
            .updatePersonalData(this.dataSend, this.id)
            .then(response => {
              this.showModal(false)
              this.cleanPasswords()
              if (!response.error) {
                this.message = this.$t('Copla.Common.Modal.UserUpdated')
                this.logout()
              } else {
                this.loadData()
                this.message = 'Error: ' + response.data
              }
            })
        }
      } else {
        this.message = ''
        this.showModal(true, this.$t('Copla.Common.Modal.Updating'))
        this.usersAPI
          .updatePersonalData(this.dataSend, this.id)
          .then(response => {
            this.showModal(false)
            this.cleanPasswords()
            if (!response.error) {
              this.message = this.$t('Copla.Common.Modal.UserUpdated')
              this.logout()
            } else {
              this.loadData()
              this.message = 'Error: ' + response.data
            }
          })
      }
    },
    checkForm (e) {
      this.message = ''
      let passwordEqual = this.password1 == this.password2
      let emailBlank = this.email == ''
      if (!passwordEqual) {
        alert(this.$t('Copla.Common.Errors.PasswordsEquals'))
      } else if (emailBlank) {
        alert(this.$t('Copla.Common.Errors.EmailFieldEmpty'))
      } else {
        this.updateUser()
      }
      e.preventDefault()
    },
    loadData () {
      this.showModal(true, this.$t('Copla.Common.Modal.Loading'))
      let self = this
      this.usersAPI.getPersonalData().then(response => {
        this.showModal(false)
        if (!response.error) {
          self.email = response.data.email
          this.oldEmail = this.email
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style scoped>
.updated-box {
  color: red;
  font-weight: bold;
}
.active {
  background-color: yellow !important;
  color: black;
}
.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#messages {
  min-height: 100px;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
